.registration-header {
  margin-bottom: 30px;
}
.registration-header .contact-info {
}
.registration-header .advisors-info {
}

.contact-info h5 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
}
.contact-info p {
  font-size: 12px;
  font-weight: 500;
}
.advisors-info {
  cursor: pointer;
  position: relative;
  display: flex;
  background-color: rgba(222, 53, 11, 0.1);
  border-radius: 12px;
  padding: 10px 18px;
  gap: 10px;
  align-items: center;
  max-width: 260px;
  width: 100%;
}
.advisors-info > span {
  font-size: 15px;
  margin-right: auto;
  font-weight: 500;
}
.advisors-info .advisors-images {
  display: flex;
}
.advisors-info .advisors-images img + img {
  margin-left: -10px;
}
.advisors-info > svg {
  fill: #DE350B;
  cursor: pointer;
}

.popover-box {
  position: absolute;
  right: 0;
  background: #fff;
  min-width: 320px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  z-index: 9;
  border-radius: 16px;
  top: calc(100% + 12px);
  .popover-box-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
    border-bottom: 1px solid #e5e5e5;
    .advisors-images {
      margin-bottom: 24px;
      img {
        width: 54px;
        height: 54px;
      }
    }
    h4 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    p {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 30px;
      color: #909090;
      span {
        font-weight: bold;
        margin-right: 6px;
        display: inline-block;
      }
    }
    .popover-waiting-time {
      font-size: 13px;
      padding: 4px 12px;
      border-radius: 12px;
      background-color: #f5f5f5;
      font-family: "DM Sans";
      font-weight: 500;
      span {
        display: inline-block;
        margin-left: 8px;
        position: relative;
        color: #198139;
        padding-left: 12px;

        &:before {
          content: "";
          border-radius: 4px;
          display: block;
          background-color: #198139;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 5px;
          height: 5px;
          position: absolute;
        }

        &:after {
          content: "";
          border-radius: 4px;
          display: block;
          background-color: #198139;
          left: 0;
          top: 7px;
          width: 5px;
          height: 5px;
          position: absolute;
          animation: pulse 1.5s ease-in infinite;
        }
      }
    }
  }
  .popover-box-footer {
    padding: 24px;
    text-align: center;
    .btn {
      max-width: 100%;
      background-color: #DE350B;
      text-align: center;
      padding: 8px 24px;
      color: #fff;
      gap: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 18px;
      &:after {
        display: none;
      }
      &:hover {
        background-color: #c8300a
        ;
      }
    }
    a {
      display: block;
    }
  }
}

@media screen and (max-width: 767px) {
  .btn {
    padding: 0 24px;
  }
  .btn.btn-back {
    padding-right: 24px;
  }
  .contact-info h5 {
    font-size: 14px;
  }
  .contact-info p {
    font-size: 10px;
  }
  .advisors-info {
    width: auto;
    padding: 8px 14px;
  }
  .advisors-info > span {
    display: none;
  }
  .advisors-info .advisors-images img {
    width: auto;
  }
}

@media screen and (max-width: 479px) {
  .popover-box {
    min-width: 84vw;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

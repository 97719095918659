// Registration Page : starts
.side-wizards {
  font-family: "Poppins";
  font-weight: bold;
  padding-bottom: 60px;
  position: sticky;
  top: 102px;
}
input#ansid {
  padding: 4px;
  margin-right: 14px;
  margin-top: auto;
  margin-bottom: auto;
}
.form_sec {
  border: 1px solid #d9d9d9;
  padding: 14px 27px;
  border-radius: 13px;
}
span.ans_delete_btn {
  color: red;
  cursor: pointer;
  padding: 0px 8px;
}
span.ans_edit_btn {
  color: green;
  cursor: pointer;
  padding: 0px 4px;
}
.title_h{
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
}
.side-wizards h3 {
  font-size: 24px;
  color: #222;
  font-weight: bold;
  margin-bottom: 30px;
}
.side-wizards ul {
  position: relative;
  padding-bottom: 30px;
  padding-top: 10px;
}
.side-wizards ul:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 0;
  width: 1px;
  border-left: 1px dashed #ee4854;
  height: 100%;
}
.side-wizards ul li {
  font-size: 16px;
  color: #222;
  font-weight: 600;
  position: relative;
  padding-left: 24px;
  margin-top: 30px;
}
.side-wizards ul li:first-child {
  margin-top: 0;
}
.side-wizards ul li:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #ee4854;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #ee485440;
  left: 0;
  top: calc(50% - 6px);
}
.side-wizards ul li.active:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #ee4854;
  border-radius: 50%;
  left: 3px;
  top: calc(50% - 3px);
}
//Registration Page Media Query : starts //
@media screen and (max-width: 991px) {
  .form-header h5 {
    margin-bottom: 8px;
  }
  .form-header {
    margin-top: 10px;
  }

  .side-wizards {
    padding-bottom: 0;
  }
  .side-wizards h3 {
    display: none;
  }
  .side-wizards ul {
    display: flex;
    padding: 0;
    padding-top: 48px;
    margin-bottom: 36px;
  }
  .side-wizards ul:before {
    height: 12px;
    border-radius: 12px;
    width: 100%;
    margin: 0;
    z-index: -1;
  }
  .side-wizards ul li {
    margin: 0;
    flex-grow: 1;
    background-color: #e5e5e5;
    padding: 0;
    border-radius: 12px;
    position: static;
    height: 6px;
    margin: 0 4px;
    font-size: 20px;
  }
  .side-wizards ul li.active {
    background-color: #ee4854;
  }
  .side-wizards ul li:before {
    display: none;
  }
  .side-wizards ul li.active:after {
    display: none;
  }
  .side-wizards ul li span {
    display: none;
  }
  .side-wizards ul li.current span {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    white-space: nowrap;
  }
}

@media screen and (max-width: 767px) {
  .form-header {
    flex-direction: column;
    text-align: center;
  }
  .form-header .contact-info {
    padding: 0;
    margin-bottom: 12px;
  }
  .form-header > h5 {
    margin-bottom: 0;
    font-size: 16px;
  }
  .form-header h5 {
    text-align: initial;
  }
  .form-content {
    padding: 20px 16px;
    border-radius: 10px;
  }

  .form-content form button.register {
    margin-top: 30px;
  }
  .form-content form .flex-row-reverse {
    justify-content: center;
  }
  .total-question-time > div {
    width: 110px;
    height: 30px;
  }
  .total-question-time > div > span:first-child,
  .total-question-time > div > span:last-child {
    height: 30px;
  }
  .insurance-introduction,
  .assessment-col {
    order: 2;
  }
}
// Term Insurance Page

// radio box btn start
.radio-box {
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  width: 100%;
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  left: 10%;
  top: 13px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    // -webkit-appearance: none;
    // -moz-appearance: none;
    background: #fff;
    border: 2px solid #d5d5d5;
    border-radius: 50%;
    cursor: pointer;
    width: 14px;
    height: 14px;
    outline: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;

    &::after {
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      content: "";
      display: block;
      height: 0.75rem;
      left: 15%;
      position: absolute;
      top: 30%;
      transform: rotate(45deg) translate(-50%, -50%);
      width: 0.375rem;
    }

    &:checked {
      background: #ee4854;
      border-color: #ee4854;
    }
  }

  .radio-box:hover .radio {
    border-color: #d5d5d5;

    &:checked {
      border-color: #ee4854;
    }
  }
}

.plan-details {
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: border-color 0.2s ease-out;
}

.radio-box:hover .plan-details {
  border-color: #d5d5d5;
}

.radio:checked ~ .plan-details {
  border-color: #ee4854;
}

.radio:disabled ~ .plan-details {
  color: #ee4854;
  cursor: pointer;
}

.radio:disabled ~ .plan-details .plan-type {
  color: #d5d5d5;
}

.card:hover .radio:disabled ~ .plan-details {
  border-color: #d5d5d5;
  box-shadow: none;
}

.radio-box:hover .radio:disabled {
  border-color: #d5d5d5;
}
// radio box btn end

.insurance-listing {
  height: 1050px;
  background: #eff2f880;
}
.edit-info {
  padding: 18px 0 8px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 7;
}
.edit-info .edit-info-btn {
  background: none;
  margin: 0 16px;
  color: #ee4854;
  position: relative;
  font-size: 12px;
  padding: 8px 24px 8px 6px;
  border: none;
}
.edit-info .edit-info-btn::after {
  position: absolute;
  content: "";
  border-width: 0 2px 2px 0;
  border-color: #ee4854;
  border-style: solid;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  background: transparent;
  right: 6px;
  top: 12px;
}

.insurance-listing-dropdown {
  padding: 16px 0;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 5;
}
// .insurance-listing-dropdown > li {
//   padding: 0 calc(1.75rem - 16px);
// }
// .insurance-listing-dropdown h5 {
//   font-weight: 500;
//   font-size: 12px;
//   color: #959595;
// }
.insurance-listing-dropdown li {
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ee4854;
}
// .insurance-listing-dropdown li p::after {
//   position: absolute;
//   content: "";
//   border-width: 0 2px 2px 0;
//   border-color: #ee4854;
//   border-style: solid;
//   width: 8px;
//   height: 8px;
//   transform: rotate(45deg);
//   background: transparent;
//   right: 10px;
//   top: 6px;
// }
.filter-dropdown {
  width: 100%;
  text-align: center;
}
// .lifecover-dropdown {
//   width: 34%;
// }
.listing-dropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 3;
  background: #fff;
  border: 1px solid #cccccc50;
  padding: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  left: 0;
  right: 0;
}
.dropdown-heading {
  font-size: 13px;
  font-weight: 500;
}

.listing-dropdown .input_content select {
  margin-bottom: 0;
  border-radius: 8px;
}

.input_content input:focus,
.input_content select:focus {
  outline: none;
  border-color: #ee4854 !important;
}

.plan-amount {
  font-size: 12px;
  text-align: center;
}

.coverage-dropdown {
  width: 33%;
  border-left: 1px solid #4e4e4e;
  border-right: 1px solid #4e4e4e;
}

.payment-dropdown {
  width: 33%;
}

.dropdown-is-open {
  filter: blur(2px) opacity(0.4);
}
.dropdown-is-open::after {
  position: absolute;
  inset: 0;
  z-index: 2;
}

.insurance-card {
  margin: 2rem 1rem;
  position: relative;
}
.insurance-card-wrapper {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 1rem;
  position: inherit;
  z-index: 1;
}
.insurance-card-wrapper .left-block img {
  max-width: 70px;
  max-height: 45px;
}
.insurance-planname {
  font-weight: 500;
  font-size: 10px;
  color: #787878;
  margin: 8px 0;
}
.insurance-claim-settle {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #4e4e4e;
}
.insurance-card .right-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listing-btn-primary {
  background: #ee4854;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  border: none;
  width: 105px;
  height: 35px;

  &:hover {
    background-color: #c8300a;
  }
}
.listing-btn-primary:disabled {
  background: #2538581a;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #25385866;
  border: none;
  width: 105px;
  height: 35px;
}
.listing-btn-secondary-small {
  background: #ee485433;
  font-size: 8px;
  color: #ee4854;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  padding: 4px 10px;
  margin-top: 12px;
}
.listing-btn-secondary-small svg {
  margin-left: 4px;
  transform: translateY(-1px);
}

.more-plan-btn {
  width: 127px;
  height: 142px;
  background: #f7aeb5;
  border: 1px solid #f7aeb5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: absolute;
  top: 14px;
  right: 0;
  display: flex;
}
.more-plan-btn span {
  font-weight: 500;
  font-size: 9px;
  color: #ee4854;
  text-align: center;
  width: 100%;
  padding: 6px;
  margin: auto 0 0;
}

.question-count > span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: #ccc;
  border-radius: 50%;
  font-weight: 600;
  margin-left: 1rem;
}
.linear-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 338px;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.mis_file_im_mod .modal-dialog {
  max-width: 436px;
  border-radius: 24px;
}
.menu_close {
  position: absolute;
  z-index: 999999999;
  right: 16px;
  top: 16px;
}
@media screen and (min-width: 768px) {
  .menu_close{
    display:none;
  }
  .title.input_content.trans_grey.filter-dropdown {
    margin-bottom: 14px;
}
  // .dashboard-list {
  //   position: absolute;
  //   background: #eee;
  //   z-index: 99999999;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   // left: -100%;
  //   padding-left: 27px;
  //   margin-top: 0px;
  //   padding-top: 27px;
  //   transition: left 0.5s ease-in-out;
  // }
}
@media (min-width: 768px) {
  .dashboard-list {
    display: block !important;
  }
  
}
@media (min-width: 1024px) {
  
}
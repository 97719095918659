header {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 0 -3px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.header-wrap {
  display: flex;
  height: 72px;
  padding: 12px 0;
  justify-content: space-between;
}

.brand-logo {
  max-width: 170px;
}

.brand-logo img {
  height: 100%;
}

img {
  width: 100%;
}

.user-info {
  display: flex;
  gap: 10px;
  align-items: center;
  min-width: 200px;
}

.user-info .user-info-left img {
  box-shadow: 0px 10px 20px rgba(0, 64, 128, 0.1);
}

.user-info .user-info-right {
}

.user-info .user-info-right div {
  font-size: 11px;
  font-weight: 500;
  color: #272755;
  font-family: "DM Sans";
}

.user-info .user-info-right h6 {
  font-family: "DM Sans";
  font-size: 14px;
  color: #272755;
  font-weight: 500;
  margin-bottom: 0;
}

footer {
  overflow: hidden;
  position: relative;
}
footer .footer-wrapper {
  display: flex;
  min-height: 52px;
  color: #fff;
}
footer .footer-wrapper > div {
  width: 40%;
}
footer .footer-wrapper > ul {
  width: 20%;
}
.footer-left {
  position: relative;
  display: flex;
  align-items: center;
}
.footer-left:after,
.footer-right:before {
  content: "";
  display: block;
  width: 200%;
  height: 100%;
  position: absolute;
  right: 0;
  background-color: #ee4854;
  border-top-right-radius: 100px;
  bottom: 0;
  z-index: -1;
}
.footer-right:before {
  left: 0;
  border-top-left-radius: 100px;
}
.footer-left > p {
  font-size: 14px;
}
.footer-social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.footer-social-icons > li {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-social-icons > li > a {
  color: #ee4854;
}
.footer-right {
  position: relative;
}
@media screen and (max-width: 991px) {
  footer .footer-wrapper > div {
    width: 35%;
  }
  footer .footer-wrapper > ul {
    width: 30%;
  }
  .footer-left > p {
    font-size: 9px;
  }
}
@media screen and (max-width: 767px) {
  .brand-logo {
    max-width: 140px;
  }
  .user-info {
    min-width: 150px;
  }
  footer .footer-wrapper .footer-right {
    display: none;
  }
  footer .footer-wrapper {
    flex-direction: column-reverse;
    position: relative;
    row-gap: 10px;
  }
  footer .footer-wrapper > ul {
    width: 100%;
    background-color: #ee4854;
  }
  .footer-social-icons {
    gap: 15px;
  }
  .footer-social-icons > li > a {
    color: #fff;
  }
  footer .footer-wrapper > div {
    width: 100%;
  }
  .footer-left {
    position: static;
    justify-content: center;
  }
  .footer-left:after {
    border-top-right-radius: 0px;
    right: initial;
  }
}

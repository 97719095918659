@media screen and (max-width: 1440px) {
  .dasboard-lead.form-content,
  .mis-report.form-content {
    padding: 24px;
  }
  .dasboard-lead.form-content .table thead th,
  .mis-report.form-content .table thead th{
    padding: 13px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1366px) {
  .dashboard-footer div:first-child {
    margin-top: 100px;
    margin-left: 0;
  }
  .dashboard-footer h2 {
    font-size: 30px;
  }
  .dashboard-footer-img {
    margin-left: auto;
  }
}
@media screen and (max-width: 1199px) {
  .form-content {
    padding: 30px;
  }
}
@media screen and (max-width: 991px) {
  .modal .modal-dialog {
    margin: 30px;
  }
  .dashboard-footer-img {
    width: 40vw;
  }
  .login_section {
    margin-top: 0;
  }
  .registration-header {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0 20px;
  }
  .training_certify_modal .modal-body .button_container {
    flex-direction: column;
    gap: 20px;
  }
}
@media screen and (max-width: 479px) {
  .modal .modal-dialog {
    margin: 24px;
  }
  .popover-box {
    min-width: 84vw;
  }
  .dashboard-footer {
    flex-direction: column;
  }
  .question-options>li {
    width: 100%;
  }
}

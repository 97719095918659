@import url("./styles/_fonts.scss");
@import url("./styles/_theme.scss");

body {
  margin: 0;
  background-color: #f5f6f8;
  font-family: "DM Sans";
  /* font-family: 'DM Sans'; */
  // font-family: 'Poppins';
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  color: #ee4854;
  text-decoration: none;
}
a:hover {
  color: #c8300a;
}

.App {
  overflow: hidden;
}

.wrapper {
  padding-top: 84px;
}

.container-fluid {
  padding-left: 48px;
  padding-right: 48px;
}

::-webkit-scrollbar-track {
  background-color: #d5d5d5;
}

::-webkit-scrollbar {
  height: 3px !important;
  width: 5px !important;
  background-color: #d5d5d5;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #ee4854;
}

main {
  min-height: calc(100vh - 124px);
  padding-top: 30px;
}
.btn {
  border: none;
  font-size: 15px;
  font-family: "Poppins";
  letter-spacing: 1px;
  font-weight: 500;
  min-width: 180px;
  width: auto;
  line-height: 40px;
  border-radius: 5px;
  position: relative;
  text-align: center;
  padding: 0 36px;
}
.btn.btn-back {
  text-align: right;
  padding-right: 60px;
}
// .btn.btn-primary:after,
// .btn.btn-secondary:after {
//   content: "";
//   background: url("./../public/assets/images/button-arrow-right.png") no-repeat;
//   width: 38px;
//   height: 40px;
//   position: absolute;
//   right: 10px;
// }

.btn.btn-back:after {
  transform: rotate(180deg);
  left: 15px;
}
.btn-primary {
  background: var(--primary);
}
.btn-primary:hover {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}
.btn-primary:focus {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}
.btn-secondary {
  background: var(--primary);
}
.btn-white {
  background: #fff;
  color: #f2b828;
  margin: 24px auto 0;
  color: #f2b828;
}
.btn-white:hover {
  background-color: #fff;
  color: #e79c25;
}
.btn-white:after {
  display: none;
}

.btn:focus,
.btn:active:focus {
  box-shadow: 0 0 5px rgba(254, 198, 0, 0.6);
}

header.before-login {
  background-color: transparent !important;
  position: static;
  background: none;
  border: none;
  padding: 44px 0 20px;
  margin: 0 auto;
  img {
    width: 200px;
  }
}

/* registration section*/
.form-content {
  background-color: #fff;
  padding: 36px 42px;
  border-radius: 16px;
  margin-bottom: 60px;
}
.form-content > h3 {
  font-size: 21px;
  font-family: "Poppins";
  color: #1e1e1e;
  font-weight: 700;
  margin-bottom: 36px;
}
.assessment .form-content > h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-content > h3 > small {
  font-size: 13px;
  font-weight: normal;
  color: #606060;
}

.form-header {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
}

.form-check {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
}
.input_content {
  position: relative;
  margin-top: -15px;
}

.input_content img {
  display: none;
}

.input_content.verified_tag img {
  position: absolute;
  display: flex;
  width: 20px;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
.input_content label {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 16px;
  padding: 0 2px;
  margin-bottom: 14px;
}

.label_margin_top{
  margin-top: 14px;
}

.input_content_margin_top {
  margin-top: -7px;
}

.input_content_margin_top_tooltip {
  margin-top: -14px;
}

.input_content.label-d-none {
  margin-top: auto;
}
.input_content.label-d-none label {
  display: none;
}
.input_content input,
.input_content select {
  font-size: 14px;
  height: 54px;
  width: 100%;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  padding: 9px 18px;
  margin-bottom: 15px;
  color: #212121;
  font-weight: 500;
  background: #fff !important;
}
.input_content input[type="password"] {
  letter-spacing: 4px;
}

.input_content input:focus,
.input_content select:focus {
  outline: none;
  border-color: #ee4854 !important;
}

.input_content.input-question label {
  display: none;
}
.input_content.input-question input {
  margin: 8px 0;
  height: 40px;
}
.input_content.input-question input:read-only {
  border: none;
  cursor: pointer;
  margin: 0;
}

.radio-container {
  position: relative;
  padding-left: 20px;
  font-size: 14px;
  color: #091b3d;
  cursor: pointer;
  display: inline-block;
  font-family: "DM Sans";
  padding-right: 16px;
}
.radio-container input {
  opacity: 0;
  visibility: hidden;
  width: 0;
}
.radio-circle {
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: calc(50% - 8px);
  border: 2px solid #d5d5d5;
  border-radius: 50%;
}

.radio-container > input:checked ~ .radio-circle {
  border: 6px solid #ee4854;
  background-color: #fff;
}

// .radio-list {
// }
.radio-list > li {
  margin-top: 18px;
}

.form-content .radio-container {
  font-weight: bold;
}

/*registration-upload*/

.registration-upload .form-content h3 {
  display: block;
}
.registration-upload .form-content h3 span {
  font-size: 14px;
}
.file-upload-box {
  position: relative;
}
.file-upload-label {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  top: -10px;
  left: 20px;
  background-color: #fff;
  padding: 0px 8px;
}
.file-upload-box .dropzone {
  background: rgba(245, 181, 68, 0.05);
  border: 2px dashed #f5b544;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
}
.file-upload-box .dropzone img {
  width: auto;
  margin-bottom: 15px;
}
.file-upload-box .dropzone p {
  font-size: 11px;
  font-weight: 700;
  font-family: "Poppins";
}
.file-upload-box .dropzone p span {
  color: #ee4854;
}

.course-card.active {
  figure {
    position: relative;
  }
  figure:before {
    content: "";
    position: absolute;
    background-color: #f2b828;
    width: 100%;
    z-index: 0;
    top: 50%;
    left: 50%;
    border-radius: 16px;
    height: 100%;
    animation: blowOut 1.5s ease-in infinite;
  }
  h5 {
    color: #f2b828;
  }
}

.course-card figure img {
  object-fit: cover;
  border-radius: 16px;
  overflow: hidden;
  height: 100%;
  max-height: 124px;
  position: relative;
  z-index: 1;
}
.course-card h5 {
  font-size: 18px;
  font-weight: 500;
  font-family: "DM Sans";
  color: #091b3d;
  margin-bottom: 0;
}
// @media screen and (max-width: 991px) {
//   .file-uploaded-box {
//     margin-bottom: 25px;
//   }
// }

/* login section start */

.login_section {
  position: relative;
  margin-top: 12rem;
}

.login-form-box::before {
  content: "";
  background: url(../public/assets/images/login-elements.png) no-repeat;
  position: absolute;
  width: 942px;
  height: 940px;
  z-index: -1;
  top: -160px;
  left: 25%;
  transform: translateX(-50%);
}

.login_section img {
  max-width: 370px;
}

.login_section .login_section_header_img {
  width: 263.5px;
  margin: 47px 0;
}
.login_section .login_para_quote {
  font-size: 30px;
  color: #646464;
  font-weight: 500;
  width: 100%;
  margin-top: 72px;
  margin-bottom: 8px;
}
.login_section .login_branding_quote {
  font-size: 24px;
  color: #ee4854;
  font-weight: 700;
  width: 75%;
}

/* login section end */

/* login form section start */
.login-form-box {
  background-color: #fff;
  border-radius: 48px;
  padding: 40px;
  max-width: 480px;
  margin-left: auto;
  position: relative;
}

.login-form-box .login-btn {
  width: 100%;
  text-align: center;
  display: block;
  background: var(--primary);
  border-radius: 16px;
  padding: 14px 24px;
  font-size: 18px;
  color: #fff;
  font-family: "Poppins";
  font-weight: bold;
  border: none;
  outline: none;
  transition: all 0.2s ease;

  &:hover {
    background: #c8300a;
  }
}
.login-form-box h3 {
  font-weight: bold;
  font-size: 24px;
  color: #091b3d;
  font-family: "Poppins";
  margin-bottom: 14px;
}
.login-form-box p {
  font-weight: 700;
  font-size: 15px;
  color: #646464;
  margin-bottom: 32px;
}
.login-form-box + p {
  font-weight: 700;
  font-size: 16px;
  color: #091b3d;
  font-family: "Poppins";
  margin-top: 42px;
}
.login-form-box .password_strength_checker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 30px;
}
.login-form-box .password_strength_checker li {
  width: 100%;
  height: 4px;
  background-color: #091b3d10;
  border-radius: 15px 15px 0px 0px;
}
.login-form-box .password_strength_checker > li + li {
  margin-left: 8px;
}
.login-form-box .password_strength_checker > li.checked:first-child,
.login-form-box .password_strength_checker > li.checked:nth-child(2) {
  background: #e6521f;
}
.login-form-box .password_strength_checker > li.checked:nth-child(3),
.login-form-box .password_strength_checker > li.checked:nth-child(4) {
  background: #000000;
}

.login-form-box .password_strength_checker > li.checked:nth-child(5),
.login-form-box .password_strength_checker > li.checked:last-child {
  background: #4b9f47;
}
/* login form section end */

/* training & certification modal start */
.training_certify_modal .modal-header {
  border-bottom: none;
}
.training_certify_modal .modal_close_btn {
  background: none;
  border: none;
  outline: none;
  margin-left: auto;
}
.training_certify_modal .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.training_certify_modal .modal-body h2 {
  text-align: center;
  margin: 16px auto 26px;
}
.training_certify_modal .modal-body p {
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 41px;
  color: #000000;
  width: 100%;
  max-width: 509px;
}
.training_certify_modal .modal-body .vector_img_container {
  width: 100%;
  display: flex;
  margin: 13px 0 50.5px;
}
.training_certify_modal .modal-body img {
  width: 100%;
  max-width: 327.5px;
  margin: 0 auto;
}
.training_certify_modal .modal-body .button_container {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}
/* training & certification modal end */

/* POSP ID modal start */
.modal-dialog {
  max-width: 990px;
  border-radius: 24px;
}
.modal-content {
  border-radius: 24px;
}
.modal-body {
  padding: 30px 25px;
}
.pospid_modal .modal-body h3 {
  font-size: 22px;
  font-weight: bold;
  font-family: "Poppins";
  margin: 0 0 30px;
  padding-right: 36px;
}
.pospid_modal .modal_close_btn {
  // background: none;
  border: none;
  outline: none;
  margin-left: auto;
  margin-bottom: auto;
  position: absolute;
  right: 15px;
  top: 15px;
}

.pospid_modal .modal-body .input_content {
  margin-bottom: 9px;
  max-width: 300px;
}
.pospid_modal .modal-body .input_content .verified_tag::before {
  background: url(../public/assets/images/verified_svg.svg) right top no-repeat;
}
.pospid_modal .modal-body .input_content input {
  margin-bottom: 0;
}
.pospid_modal .modal-body .sbid_label {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 10px;
  color: #000000;
  margin-bottom: 18px;
}
.pospid_modal .modal-body h2 {
  font-family: "Poppins";
  font-weight: bold;
  font-size: 28px;
  color: #000000;
  margin: 16px 0;
}
.pospid_modal .modal-body p {
  font-family: "Poppins";
  font-size: 16px;
  color: #919191;
  margin-bottom: 15px;
}
.pospid_modal .modal-body .tnc_label {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: auto 0;
}
.pospid_modal .modal-body .agree_btn {
  margin: 25px 6px 15px;
}
/* POSP ID modal end */
/* training & certification complete modal start */
.training_complete_modal .modal-dialog {
  width: 100%;
  max-width: 671.75px;
  border-radius: 24px;
}
.training_complete_modal .modal-content {
  border-radius: 24px;
}
.training_complete_modal .modal-header {
  border-bottom: none;
}
.training_complete_modal .modal-header h3 {
  margin: 17px 10px 0;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #e70000;
}
.training_complete_modal .modal_close_btn {
  // background: none;
  border: none;
  outline: none;
  margin-left: auto;
  margin-bottom: auto;
}
.training_complete_modal .modal-body {
  padding: 6px 26px 21px;
}
.training_complete_modal .modal-body p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.training_complete_modal .modal-body p + p {
  margin-top: 20px;
}
.training_complete_modal .modal-body .input_content {
  margin-bottom: 9px;
  max-width: 300px;
}
.training_complete_modal .modal-body .input_content .verified_tag::before {
  background: url(../public/assets/images/verified_svg.svg) right top no-repeat;
}
.training_complete_modal .modal-body .input_content input {
  margin-bottom: 0;
}
.training_complete_modal .modal-body .sbid_label {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #000000;
  margin-bottom: 13px;
}
.training_complete_modal .modal-body h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 24px;
  color: #000000;
  margin: 38px 0 16px;
}
.training_complete_modal .modal-body .tnc_label {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: auto 0;
}
.training_complete_modal .modal-body > div {
  width: 100%;
  display: flex;
}
.training_complete_modal .modal-body button {
  margin: 36px auto;
}
/* training & certification complete modal end */

/* exam passed content start*/
.exam_passed_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.exam_passed_content .content_heading {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin: 6px auto 19px;
}
.exam_passed_content .content_name {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
}
.exam_passed_content > img {
  width: 100%;
  max-width: 402px;
  margin: 28px auto 60px;
  border-radius: 12px;
}
.exam_passed_content > .success_icon {
  width: 100%;
  max-width: 200px;
  border: 3px dashed #ccc;
  margin-bottom: 14px;
}
/* exam passed content end*/

/* quotes side card start*/
.get_quotes_card {
  background: url("../public/assets/images/quote_card.png") center no-repeat;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 30px 24px;
  font-family: "Poppins";
  justify-content: center;
  min-height: 230px;
  font-size: 18px;
  background-size: cover;
  filter: hue-rotate(310deg) saturate(3) brightness(1);
}
.get_quotes_card p {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}
.get_quotes_card button {
  padding: 0 16px;
  text-align: center;
}
/* quotes side card end*/

/* posp assesment start*/
.posp-assesment .form-header {
  justify-content: flex-end;
  margin-right: 22px;
}
.total-question-time {
  display: flex;
  align-items: center;
}
.total-question-time > p {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #000000;
}
.total-question-time > div {
  width: 146px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 7px;
  margin-left: 32px;
}
.total-question-time > div > span:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  background: #ee4854;
  border-radius: 7px 0px 0px 7px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}
.total-question-time > div > span:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #888888;
}
/* posp assesment end*/

/* clock side card start*/
.timer-card,
.circular-progress-card {
  padding: 30px;
  border: none;
  border-radius: 16px;
  margin-bottom: 24px;
}
.timer-card .card-progress,
.circular-progress-card .card-progress {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timer-card svg,
.circular-progress-card svg {
  width: 120px;
  height: 120px;
}
.timer-card h3 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 18px;
  color: #ee4854;
  letter-spacing: 2px;
}
.circular-progress-card h5 {
  text-align: center;
  margin-top: 24px;
}
.circular-progress-card span {
  text-align: center;
  color: #909090;
}
/* clock side card end*/

/* MCQ question card start*/
.questions-list {
  counter-reset: count;
  list-style: none;
}
.questions-list > li {
  counter-increment: count;
}
.questions-list > li h3::before {
  content: counter(count);
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #eee;
  border-radius: 50%;
  font-family: "DM Sans";
  font-weight: 600;
}
.question-block {
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 16px;
  margin-bottom: 30px;
}
.question-block > h3 {
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}
.question-options {
  counter-reset: options;
  list-style: none;
  padding-left: 48px;
  display: flex;
  flex-wrap: wrap;
}
.question-options > li {
  counter-increment: options;
  font-size: 16px;
  width: 50%;
  margin-bottom: 5px;
  color: #707070;
}
.question-options > li::before {
  margin-right: 8px;
  content: counter(options, upper-alpha) ".";
  color: #212121;
  font-weight: bold;
}
.question-block-action {
  display: flex;
  margin-top: 8px;
  padding-top: 16px;
  border-top: 1px solid #e5e5e5;
  justify-content: space-between;
  align-items: center;
}
.question-block-action > span {
  border-radius: 8px;
  padding: 6px 12px;
  background-color: #f5f5f5;
}
.question-block-action > ul {
  counter-reset: answers;
  display: flex;
  gap: 12px;
}
.question-block-action > ul > li {
  counter-increment: answers;
  width: 30px;
  height: 30px;
  position: relative;
}
.question-block-action > ul > li span::before {
  content: counter(answers, upper-alpha);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  font-family: "DM Sans";
  font-weight: 600;
}
.question-block-action > ul > li [type="radio"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.question-block-action > ul > li [type="radio"]:not(:checked) + span::before {
  background-color: #fff;
  color: #555;
}
.question-block-action > ul > li [type="radio"]:checked + span::before {
  background-color: #de350b;
  color: #fff;
}
.question-card {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 20px 12px;
  margin-bottom: 30px;
}
.question-card > div:first-child {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.question-card .main-question {
  margin-left: 5px;
  width: 100%;
}
.question-card .main-question > p {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  margin: 4px 6px 20px;
}
.question-card .multiple-answer > p {
  font-weight: 500;
  font-size: 11px;
  line-height: 150%;
  color: #000000;
}
.question-card .multiple-answer > p + p {
  margin-top: 8px;
}
.question-card .question-no {
  width: 28px;
  height: 28px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #000000;
  background-color: #eee;
}
.question-card .card-divider {
  width: 100%;
  max-width: 494px;
  height: 1px;
  background: #eeeeee;
  margin: 16px 4px;
}
.question-card .mark-badge {
  width: 77px;
  height: 25px;
  background: #eeeeee;
  border-radius: 7px;
  padding: 6px 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  color: #000000;
}
.question-card .select-answer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.select-answer > .radio-label-text + .radio-label-text {
  margin-left: 8px;
}
.radio-label-text > label.form-check-label {
  width: 25px;
  padding: 0;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}
.radio-label-text div.radio-label-text {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  position: relative;
}
.radio-label-text div.radio-label-text p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  background: #fff;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
  border-radius: 50%;
}
.radio-label-text div.radio-label-text p {
  background: transparent;
}
.radio-label-text input {
  display: none;
}
.radio-label-text input {
  display: none;
}
.radio-label-text input:checked + .radio-label-text {
  border: 1px solid #eee;
  background: #ee4854;
  color: #fff !important;
}
.radio-label-text input:checked + .radio-label-text p {
  color: #fff !important;
}
// @media screen and (max-width: 767px) {
//   .assessment .form-content > h3 {
//     flex-direction: column;
//   }
//   .total-question-time {
//     margin-top: 10px;
//   }
//   .question-block {
//     padding: 12px;
//   }
//   .question-options {
//     flex-direction: column;
//     flex-wrap: nowrap;
//   }
//   .question-options > li {
//     width: 100%;
//   }
//   .question-block > h3 {
//     gap: 8px;
//     font-size: 16px;
//   }
//   .questions-list > li h3::before {
//     width: 39px;
//     font-size: 10px;
//   }
// }
/* MCQ question card end*/

/*term insurance section start*/
.insurance-filter {
  margin-top: 16px;
}
.insurance-filter label {
  background-color: #f5f6f8;
}
.insurance-filter input {
  background-color: #f5f6f8;
  margin-bottom: 48px;
  font-size: 14px;
  color: #9da4b1;
}
.insurance-filter select {
  background-color: #f5f6f8;
  height: 45px;
  color: #9da4b1;
  font-size: 14px;
  margin-bottom: 56px;
}
.insurance-filter h4 {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: "DM Sans";
  text-transform: uppercase;
  color: #666;
  margin-bottom: 20px;
}
.insurance-filter .radio-list label {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  height: 36px;
  font-weight: 400;
}
.form-header > h5 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 18px;
}
.form-header .contact-info {
  padding-left: 12px;
}
.form-header .contact-timing {
  font-size: 14px;
  display: flex;
  align-items: center;
}
.form-header .contact-timing button {
  margin: 0px 22px;
  background-color: #f5f0e7;
  color: #f5b544;
  border: 1px solid #f5b544;
  padding: 3px 25px;
  height: 28px;
  border-radius: 5px;
}
.form-header select {
  background-color: #f5f6f8;
  height: 32px;
  color: #9da4b1;
  font-size: 14px;
  padding-right: 20px;
  margin-bottom: 0;
}
.form-header label {
  background-color: #f5f6f8;
}
.insurance-filter-band {
  background-color: #f5f0e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 45px;
  border-radius: 16px;
  margin-top: 30px;
}
.insurance-filter-tab ul {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: bold;
  color: #666;
}
.insurance-filter-tab ul li {
  margin-right: 45px;
}
.insurance-filter-tab ul li.active {
  color: #ee4854;
}
.insurance-filter-band .insurance-filter-toggle {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ddd8d0;
  padding: 2px;
  border-radius: 16px;
}
.insurance-filter-band .insurance-filter-toggle span {
  padding: 5px 18px;
}
.insurance-filter-band .insurance-filter-toggle span.active {
  border-radius: 16px;
  background-color: #fff;
  color: #ee4854;
}
.insurance-filter-band-text {
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: 28px;
  padding-left: 12px;
}
.insurance-filter-band-text span {
  font-weight: 500;
  color: #000;
}
.insurance-brand-name {
  width: 11%;
}
.insurance-plan-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
}

.insurance-plan-card h5 {
  font-size: 14px;
  font-weight: 400;
}
.insurance-plan-card p {
  font-size: 10px;
  font-weight: 400;
}
.insurance-plan-card a {
  font-size: 11px;
  color: #ee4854;
  font-weight: 500;
  text-decoration: none;
}
.insurance-plan-name {
  width: 30%;
  text-align: center;
}
.insurance-riders {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  width: 15%;
}
.insurance-riders::after,
.insurance-riders::before {
  content: "";
  height: 75px;
  width: 2px;
  background-color: #ee4854;
  opacity: 0.4;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.insurance-riders::before {
  left: 0;
}
.insurance-riders::after {
  right: 0;
}
.insurance-cover {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  width: 15%;
  text-align: center;
}
.insurance-cover span {
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
.insurance-cover::after {
  content: "";
  height: 75px;
  width: 2px;
  background-color: #ee4854;
  opacity: 0.4;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.insurance-tenure {
  position: relative;
}
.insurance-tenure::after {
  content: "";
  position: absolute;
  bottom: -19px;
  left: 22%;
  background-color: #ffb500;
  opacity: 13%;
  width: 40%;
  height: 4px;
}
.insurance-tenure .tenure-period img {
  position: absolute;
  top: -32px;
  left: 130px;
  z-index: 1;
  width: 40%;
}
.insurance-tenure .tenure-period span {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: -32px;
  position: absolute;
  left: 185px;
  z-index: 2;
}
.insurance-tenure .tenure-price img {
  position: absolute;
  width: 65%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.insurance-tenure .tenure-price span {
  font-size: 21px;
  font-weight: 500;
  color: #ee4854;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.insurance-offer-section {
  text-align: center;
  background-color: #e8ecef;
  padding: 40px 75px;
  max-width: 775px;
  margin: auto;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 500;
  color: #666;
}
.insurance-terms {
  right: 0;
}
.insurance-tenure {
  width: 29%;
  text-align: center;
}
.insurance-offer-section {
  position: relative;
  margin-top: 40px;
}
.insurance-offer-section img {
  width: auto;
  position: absolute;
}
.insurance-offer-section img.baloon-img {
  top: 0;
  right: 0;
  transform: translate(40%, -25%);
}
.insurance-offer-section img.vector-1 {
  bottom: 0;
  transform: translateY(50%);
  left: 30%;
}
.insurance-offer-section img.vector-2 {
  top: 0;
  right: 37%;
  transform: translateY(-50%);
}
.insurance-offer-section img.vector-3 {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}
.insurance-offer-section .heading {
  text-align: center;
  background-color: #e8ecef;
  max-width: 775px;
  margin: auto;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 500;
  color: #666;
}
.insurance-terms {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 40px 80px 40px 0;
}
.insurance-terms a {
  color: #ee4854;
  font-weight: 500;
  text-decoration: none;
}
.insurance-terms-footer {
  background-color: #fffdfd;
  text-align: center;
  padding: 16px 0px;
}
.insurance-terms-footer img {
  width: auto;
}
.insurance-terms-footer .term-logo img {
  max-width: 180px;
}

.insurance-terms-footer h3 {
  font-size: 26px;
  font-weight: 400;
  margin: 12px 0;
}
.insurance-terms-footer .insure-address {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 35px;
}
.insurance-terms-footer .term-security-contact {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  position: relative;
}
.insurance-terms-footer .term-security-contact::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  top: 65%;
  // left: 50%;
  // transform: translateX(-50%);
  background-color: #f2f2f2;
}
.insurance-terms-footer .term-security {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  margin-bottom: 16px;
}
.insurance-terms-footer .secured_by {
  max-width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.insurance-terms-footer .secured_by span {
  font-size: 10px;
  line-height: 12px;
}
.insurance-terms-footer .irdai_registration {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  max-width: 375px;
  margin: auto;
  // margin-top: 16px;
  padding: 8px;
  background: #fff;
  position: relative;
  z-index: 2;
}
.insurance-terms-footer .irdai-logo {
  padding-right: 16px;
}
.insurance-terms-footer .irdai_info {
  font-size: 12px;
  font-weight: 500;
  color: #666;
  position: relative;
}
.insurance-terms-footer .irdai_info:before {
  content: "";
  position: absolute;
  top: 8px;
  left: -10px;
  width: 1px;
  height: 80%;
  background-color: #e5e5e5;
}
.insurance-terms-footer .customer_services {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  max-width: 375px;
  margin: auto;
  // margin-top: 45px;
  padding: 23px;
  background: #fff;
  position: relative;
  z-index: 2;
}
.insurance-terms-footer .customer_services .customer_number {
  font-size: 15px;
  font-weight: 500;
  color: #919191;
}
.insurance-terms-footer .term_copyright {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-bottom: 12px;
}
.insurance-terms-footer .term_copyright p {
  margin: 0 68px;
}
.insurance-terms-footer .term_copyright span {
  font-weight: 500;
}

// @media (min-width: 992px) {
//   .term_insurance .col-lg-10 {
//     flex: 0 0 auto;
//     width: 80%;
//   }
//   .term_insurance .col-lg-2 {
//     flex: 0 0 auto;
//     width: 20%;
//   }
// }
// @media (max-width: 1300px) {
//   .insurance-filter-band .insurance-filter-toggle {
//     height: 40px;
//   }
// }
// @media (max-width: 1199px) {
//   .container-fluid {
//     padding-left: 15px;
//     padding-right: 15px;
//   }
//   .insurance-filter-tab ul {
//     font-size: 11px;
//   }
//   .insurance-filter .radio-list label {
//     font-size: 12px;
//   }
//   .insurance-filter .radio-list label {
//     font-size: 12px;
//   }
//   .insurance-filter h4 {
//     font-size: 13px;
//   }
// }
// @media (max-width: 991px) {
//   .term_insurance {
//     position: relative;
//   }
//   .insurance-filter .input_content {
//     display: block;
//     width: 45%;
//     margin-right: 20px;
//   }
//   .insurance-filter .title.input_content {
//     display: inline-block;
//   }
//   .insurance-filter input {
//     margin-bottom: 25px;
//   }
//   .insurance-filter select {
//     margin-bottom: 25px;
//   }
//   .form-header {
//     margin-top: 90px;
//     align-items: flex-start;
//   }
//   .form-header > h5 {
//     margin-bottom: 32px;
//     margin-top: -40px;
//   }
//   .insurance-filter h4 {
//     margin-bottom: 5px;
//   }
//   .insurance-filter .radio-list {
//     display: flex;
//   }
//   .insurance-filter .radio-list label {
//     padding-left: 22px;
//   }
//   .insurance-terms-footer .term-security-contact {
//     gap: 30px;
//   }
//   .insurance-terms-footer .term-security-contact::after {
//     display: none;
//   }
//   .insurance-terms {
//     margin: 40px 0 40px 0;
//   }
// }
// @media (max-width: 767px) {
//   .insurance-filter .input_content {
//     width: 100%;
//   }
//   .insurance-filter {
//     margin-top: 50px;
//   }
//   .form-header .contact-timing {
//     flex-direction: column;
//     gap: 20px;
//   }
//   .insurance-filter-band {
//     padding: 16px 10px;
//     flex-direction: column;
//     gap: 10px;
//   }
//   .insurance-filter-tab ul li {
//     margin-right: 10px;
//   }

//   .insurance-filter-band .insurance-filter-toggle {
//     height: 35px;
//   }
//   .insurance-plan-card {
//     flex-direction: column;
//     padding: 15px;
//     gap: 7px;
//   }
//   .insurance-brand-name {
//     width: 35%;
//   }
//   .insurance-plan-name {
//     width: 100%;
//     line-height: 15px;
//   }
//   .insurance-plan-card h5 {
//     margin-bottom: 0;
//   }
//   .insurance-riders {
//     flex-direction: row;
//     width: 100%;
//     gap: 10px;
//   }
//   .insurance-riders::before,
//   .insurance-riders::after {
//     display: none;
//   }
//   .insurance-cover {
//     width: 100%;
//   }
//   .insurance-cover br {
//     display: none;
//   }
//   .insurance-tenure {
//     width: 100%;
//   }
//   .insurance-terms-footer .irdai_registration {
//     max-width: 340px;
//   }
//   .insurance-terms-footer .customer_services {
//     max-width: 340px;
//   }
//   .insurance-cover::after {
//     display: none;
//   }
// }

.show-in-tab {
  display: none;
}
.show-in-mobile {
  display: none;
}

/* term insurance modal start */
.term-insurance-modal .modal-dialog {
  max-width: 995px;
  border-radius: 24px;
}
.term-insurance-modal .modal-content {
  border-radius: 24px;
}
.term-insurance-modal .modal-body {
  padding: 30px 25px;
}
.term-insurance-modal .modal-body h3 {
  font-size: 22px;
  font-weight: bold;
  font-family: "Poppins";
  margin: 0 0 30px;
  padding-right: 36px;
}
.term-insurance-modal .modal_close_btn {
  background: none;
  border: none;
  outline: none;
  margin-left: auto;
  margin-bottom: auto;
  position: absolute;
  right: 15px;
  top: 15px;
}

.term-insurance-modal .modal-body form {
  width: 100%;
  max-width: 418px;
}
.term-insurance-modal form > .form-check {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.term-insurance-modal .modal-body .input_content {
  width: 100%;
}
.term-insurance-modal .modal-body .input_content + .input_content {
  margin-top: 48px;
}
.term-insurance-modal .modal-body .input_content input {
  margin-bottom: 0;
}
.term-insurance-modal .modal-body .input_content > label {
  width: auto;
}
.term-insurance-modal label.proceed-label {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  text-align: center;
  text-transform: capitalize;
  color: #4e4e4e;
  margin-top: 20px;
}
.term-insurance-modal label.proceed-label span {
  color: #ee4854;
}
.term-insurance-modal .agree-policy {
  width: 100%;
  display: flex;
  justify-content: center;
}
.term-insurance-modal .agree-policy > div {
  width: 100%;
  max-width: 350px;
  border: 0.4px solid #ee4854;
  border-radius: 12px;
  padding: 5px 15px;
}
.term-insurance-modal .agree-policy > div label {
  padding-right: 0;
  width: 100%;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  text-transform: capitalize;
  color: #000000;
}
/* term insurance modal end */

/* term-plans section start */
.term-plans {
  min-height: 700px;
  padding-bottom: 80px;
}
.page-description {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  width: 100%;
  margin: 20px auto 30px;
}
.term-plans div.w-100 {
  position: relative;
}
.term-plans .card-strip {
  height: 84px;
  width: 25%;
  background: #fff;
  border-radius: 30px 0 0 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 36px;
  padding-right: 24px;
  position: absolute;
  font-weight: 700;
  font-size: 20px;
}
.term-plans .card-strip1 {
  top: 25%;
  left: 5%;
  width: 20%;
}
.term-plans .card-strip2 {
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  border-radius: 0 30px 30px 0;
  padding-right: 36px;
  padding-left: 24px;
  width: 20%;
}
.term-plans .card-strip3 {
  bottom: 25%;
  left: 0;
}

// .term-plans .card-strip > span {
// }
/* term-plans section end */
/* term-plans form start   */

.term-plans-form p {
  width: 100%;
  max-width: 415px;
  font-weight: 700;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
  color: #4f4f4f;
  margin: 20px auto 0;
  letter-spacing: -0.4px;
}
/* term-plans form end */
/* intro of insurance start */
.insurance-introduction .info-content-tabs.nav-tabs .nav-link {
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  color: #091b3d40;
  margin-right: 30px;
  border: none;
  outline: none;
}
.insurance-introduction .info-content-tabs.nav-tabs .nav-link:hover {
  border: none;
  outline: none;
}
.insurance-introduction .info-content-tabs.nav-tabs .nav-link.active {
  color: #091b3d;
  border: none;
  border-bottom: 2px solid #ee4854;
}
.content-tab .content-left-col p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #091b3d;
  max-width: 248px;
}
.content-tab .content-right-col > div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.content-tab .content-right-col img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}
.content-tab .content-right-col span {
  color: #aaa;
}
.content-tab > .content-video {
  width: 100%;
}
.content-tab > .content-video video {
  border-radius: 16px;
}
.content-tab > p {
  font-size: 16px;
  color: #606060;
}
.insurance-introduction .btn-outline-warning {
  white-space: nowrap;
  background: transparent;
  border: 1px solid #d5d5d5;
}
.insurance-introduction .btn-outline-warning:hover {
  border-color: #ee4854;
  color: #ee4854;
}
.content-tab .content-video {
  position: relative;
}
.content-tab .content-video button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}
.content-tab .content-video img {
  width: 100%;
}
/* intro of insurance end */

// @media screen and (max-width: 1419px) {
//   .term-plans .card-strip1,
//   .term-plans .card-strip3 {
//     margin-left: 0;
//     padding: 0 16px;
//   }
//   .term-plans .card-strip2 {
//     margin-right: 0;
//     padding: 0 16px;
//   }
// }
// @media screen and (max-width: 1199px) {
//   .login_form_float {
//     margin: 0 auto;
//   }
//   .term-plans .card-strip1,
//   .term-plans .card-strip3 {
//     margin-left: 0;
//     padding: 0 24px;
//   }
//   .term-plans .card-strip2 {
//     margin-right: 0;
//     padding: 0 24px;
//   }
//   .term-plans .card-strip1 > span,
//   .term-plans .card-strip2 > span,
//   .term-plans .card-strip3 > span {
//     width: 80px;
//   }
//   .get_quotes_card {
//     padding: 20px 12px;
//   }
// }
// @media screen and (max-width: 991px) {
//   .hide-in-tab {
//     display: none;
//   }
//   .show-in-tab {
//     display: block;
//   }

//   .login-form-box {
//     margin: 0 auto;
//     padding: 36px;
//   }
//   .login-form-box::before {
//     right: auto;
//     left: 50%;
//     transform: translateX(-50%);
//     top: -60px;
//   }
//   .login_section .login_para_quote {
//     margin-top: 20px;
//   }
//   .login_section .login_branding_quote {
//     margin-bottom: 60px;
//   }
//   .login_section::after {
//     bottom: -55%;
//     z-index: -1;
//   }
//   .login_section .login_section {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
//   .exam_passed_container > .row,
//   .posp-assesment.container-fluid > .row {
//     flex-direction: column-reverse;
//   }
//   .exam_passed_container > .row > div:last-child {
//     margin-bottom: 2rem;
//   }
//   .term-plans div.w-100 {
//     position: initial;
//   }
//   .term-plans .card-strip1,
//   .term-plans .card-strip2,
//   .term-plans .card-strip3 {
//     position: initial;
//     max-width: 100%;
//     margin-bottom: 1rem;
//   }
//   .term-plans .card-strip1 span,
//   .term-plans .card-strip2 span,
//   .term-plans .card-strip3 span {
//     width: auto;
//     text-align: center;
//     margin: 0 auto;
//   }
//   .term-plans-form {
//     position: initial;
//     inset: 0;
//     transform: unset;
//     max-width: 100%;
//     margin-bottom: 3rem;
//   }
//   .content-tab > .content-video {
//     max-width: initial;
//   }
//   .get_quotes_card {
//     margin: 0 auto 24px;
//   }
//   .get_quotes_card button {
//     text-align: center;
//   }
//   .term-plans {
//     min-height: auto;
//   }
//   .insurance-terms-footer .term_copyright p {
//     margin: 0 16px;
//   }
// }
@media screen and (max-width: 767px) {
  //   .container {
  //     padding-left: 24px;
  //     padding-right: 24px;
  //   }
  .hide-in-mobile {
    display: none !important;
  }
  .show-in-mobile {
    display: block;
  }

  .term-plans-form {
    margin: 0;
    width: 100%;
  }

  //   header.before-login img {
  //     width: 180px;
  //   }

  //   .login-form-box::before {
  //     width: 150vw;
  //     height: 150vw;
  //   }
  //   .login_section .login_para_quote {
  //     font-size: 24px;
  //     margin-bottom: 20px;
  //   }
  //   .login_section .login_branding_quote {
  //     font-size: 18px;
  //   }

  //   .file-upload-box {
  //     margin-bottom: 24px;
  //   }
  //   .login_section .container::before {
  //     top: 42%;
  //     left: unset;
  //     right: 0;
  //   }
  //   .login_section .login_para_quote {
  //     margin-top: 40px;
  //   }
  //   .login_form_right {
  //     margin-top: 4rem;
  //   }
  //   .login_section .container::after {
  //     top: 65%;
  //   }
  //   .login_section::after {
  //     bottom: -55%;
  //     z-index: -1;
  //     right: 55px;
  //   }
  //   .term-insurance-modal .modal-dialog {
  //     max-width: 500px;
  //     margin: 2rem auto;
  //   }
  //   .term-insurance-modal .modal-dialog .col-md-5 {
  //     width: auto;
  //     margin: 1.5rem 0 2.5rem;
  //   }
  //   .term-plans-form {
  //     padding: 1rem;
  //   }
}
// @media screen and (max-width: 575px) {
//   .posp-assesment.container-fluid {
//     padding-left: 15px;
//     padding-right: 15px;
//   }
//   .posp-assesment.container-fluid .posp-assesment-content {
//     padding-left: 15px;
//     padding-right: 15px;
//   }
//   .question-card .select-answer {
//     justify-content: space-evenly;
//   }
//   .question-card .mark-badge {
//     margin: 1rem 1rem 0;
//   }
//   .term-plans-form .radio-container {
//     width: 92px;
//   }
// }
// @media screen and (max-width: 479px) {
//   .form-content > h3 {
//     display: block;
//   }
//   .form-content > h3 > .total-question-time {
//     margin-top: 1rem;
//   }
//   .question-block > h3 {
//     display: block;
//   }
//   .questions-list > li h3::before {
//     margin-bottom: 10px;
//   }
//   .question-options {
//     padding-left: 0;
//     margin-top: 16px;
//   }
//   .training_certify_modal .modal-body p {
//     font-size: 16px;
//     line-height: 22px;
//     margin-bottom: 16px;
//   }
//   .pospid_modal .modal-body h2 {
//     font-family: "Poppins";
//     font-weight: bold;
//     font-size: 20px;
//     color: #000000;
//     margin: 16px 0;
//   }
//   .pospid_modal .modal-body h3 {
//     font-size: 18px;
//   }
// }

.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  min-height: 100vh;
}

/*user-dashboard */
.user-dashboard h3 {
  margin-top: 60px;
  font-size: 34px;
  color: #4f4f4f;
}
.dashboard-layout .form-content {
  min-height: 400px;
  font-family: "Gotham", sans-serif;
}
// .dashboard-layout .form-content * {
// }
.dashboard-list {
  margin-top: 78px;
}
.dashboard-list > a {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 35px;
  gap: 12px;
  color: #4e4e4e;
  transition: 0.5s all;
}
.dashboard-list > a:hover,
.dashboard-list > a.active {
  color: #de350b;
}
.dashboard-list > a:hover svg,
.dashboard-list > a.active svg {
  stroke: #de350b;
}
.dashboard-list .list-item-icon img {
  width: auto;
  margin-right: 12px;
}
.dashboard-list .list-item-icon svg {
  transition: 0.5s all;
  width: 21px;
  height: 21px;
}
.dashboard-list .list-item-name {
  font-size: 18px;
  font-weight: 700;
}
.dashboard-advisory {
  display: flex;
  justify-content: flex-end;
}
.dashboard-card {
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
  margin-bottom: 1rem;
}
.dashboard-card.policies {
  background-color: #eaf1fe;
}
.dashboard-card .dashboard-card-name .dash-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #000;
}
.dashboard-card .dashboard-card-name .dash-number {
  font-size: 24px;
  font-weight: 500;
}
.dashboard-card.total-renewal {
  background-color: #fef8ed;
}
.dashboard-card.premium {
  background-color: #edf6ed;
}
.dashboard-card.payout {
  background-color: #ce232710;
}

.dash-icon img {
  max-width: 45px;
}
.dashboard-footer {
  display: flex;
}
.dashboard-footer div:first-child {
  margin-top: 100px;
  margin-left: 180px;
}
.dashboard-footer img {
  width: auto;
}
.dashboard-footer h2 {
  font-size: 36px;
  font-weight: 500;
}
.dashboard-footer .lower-heading {
  color: #ee4854;
  margin-bottom: 45px;
  margin-top: 15px;
}
.dashboard-footer .apple-android-links a {
  margin-right: 45px;
}
// .dashboard-footer .dashboard-footer-img {
// }

.table {
  border: none;
}
.table thead,
.table tbody,
.table th,
.table tr,
.table td {
  border: none;
}

.table thead th {
  background: #ee485420;
  padding: 16px;
  color: #212121;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
}
.table thead th:first-child,
.table tbody td:first-child {
  border-radius: 8px 0 0 8px;
}
.table thead th:last-child,
.table tbody td:last-child {
  border-radius: 0 8px 8px 0;
}

.table-hover > tbody > tr:hover,
.table-hover > tbody > tr:hover > * {
  background-color: #ee485410;
  --bs-table-accent-bg: none;
}
.table tbody td {
  padding: 16px;
  color: #909090;
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
}
.table thead th input[type="checkbox"],
.table tbody td input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

.badge {
  padding: 6px 10px;
  line-height: normal;
  font-family: "Poppins";
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
}

.badge.bg-success {
  background-color: rgba(75, 159, 71, 0.1) !important;
  color: #4b9f47;
}

.badge.bg-warning {
  background-color: rgba(245, 181, 68, 0.1) !important;
  color: #f5b544;
}

.css-checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
#inputPreview {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.css-checkbox + label {
  position: relative;
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  height: 20px;
  color: rgb(0, 0, 0);
}
.css-checkbox + label::before {
  content: " ";
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  width: 20px;
  height: 20px;
  background-color: #f5f5f5;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  box-shadow: none;
}
.css-checkbox:checked + label::before {
  background-color: #ee4854;
}
.css-checkbox:checked + label::after {
  content: " ";
  background-image: url("../public/assets/images/check-white.svg");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: center center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  left: 0px;
  top: 0px;
  text-align: center;
  background-color: transparent;
  font-size: 10px;
  height: 20px;
  width: 20px;
}

.table-filter {
  display: flex;
  justify-content: end;
  gap: 20px;
  width: 100%;
  font-family: "Cera Pro", sans-serif;

  button {
    padding: 0 25px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    background: #ee4854;
    border-radius: 8px;
    border: none;

    &:hover {
      background: #c8300a;
    }
  }

  .filter-btn {
    color: #ee4854;
    background: none;
    border-radius: 50%;
    &:hover {
      background: #fafafa;
    }
  }

  select {
    margin-bottom: 0;
  }
}

.trans_grey {
  label {
    background-color: transparent;
  }
  select {
    background: transparent;
  }
}
.filter-dropdown {
  width: 100%;
  margin-top: auto;

  label {
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    color: #091b3d80;
  }

  select {
    font-weight: 700;
    margin-bottom: 0;
    background: #de350b10;
    padding-left: 5rem;
    border: none;
    border-radius: 10px;
    background: url(../public/assets/images/arrow-down.svg) no-repeat right
      #de350b10;
    // -webkit-appearance: none;
    background-position-x: 93%;
  }
}

.filter-btn {
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  min-width: 48px;
  background-color: transparent;
  color: #ee4854;
}

// admin graph section start
.orange-curve-indicator,
.blue-curve-indicator {
  max-height: 48px;
  padding: 0 22px;
  border-radius: 16px;
  color: #091b3d;
  font-size: 14px;
  font-weight: 700;
  font-family: "Cera Pro", sans-serif;
  display: flex;
  align-items: center;
}
.orange-curve-indicator span,
.blue-curve-indicator span {
  border: 4px solid;
  border-radius: 50%;
  margin-right: 14px;
  display: inline-flex;
  width: 14px;
  height: 14px;
}
.orange-curve-indicator {
  background-color: #de350b10;
  margin-right: 1rem;
}
.blue-curve-indicator {
  background-color: #2772f010;
}
.orange-curve-indicator span {
  border-color: #de350b;
}
.blue-curve-indicator span {
  border-color: #2772f0;
}

.white-box {
  min-height: 260px;
  background-color: #fff;
  padding: 36px;
  border-radius: 16px;
  margin-bottom: 60px;
}
.white-box .recharts-default-legend,
.white-box.radial-chart .recharts-label-list {
  display: none;
}

.radial-chart-non-certify-info *,
.radial-chart-certify-info * {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
}
.radial-chart-non-certify-info h6,
.radial-chart-certify-info h6 {
  font-size: 14px;
}
.radial-chart-non-certify-info h4 {
  font-size: 24px;
  color: #f5b544;
}
.radial-chart-certify-info h4 {
  font-size: 24px;
  color: #2772f0;
}
.radial-chart-non-certify-info p,
.radial-chart-certify-info p {
  font-size: 14px;
  color: #091b3d50;
}
.radial-chart-non-certify-info .profit-signal,
.radial-chart-certify-info .profit-signal {
  font-size: 14px;
  color: #4b9f47;
}
.radial-chart-non-certify-info .loss-signal,
.radial-chart-certify-info .loss-signal {
  font-size: 14px;
  color: #e6521f;
}

.total-premium-heading {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 1rem;
}
.total-premium-amount {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
  color: #091b3d;
}
.total-premium-amount span {
  font-size: 24px;
}
.total-premium-amount p {
  font-size: 14px;
  margin-top: 10px;
}
.total-premium-percent span {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #4b9f47;
}
.average-product-sale-progess {
  font-family: "Cera Pro", sans-serif;
}
.average-product-sale-progess > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.product-sale-progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  // max-width: calc(125px - 24px);
}
.product-sale-progress h5 {
  font-weight: 700;
  font-size: 24px;
  color: #091b3d;
  text-align: center;
  margin: 24px 0 10px;
}
.product-sale-progress p {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #091b3d50;
  margin-bottom: 1rem;
}
.average-product-sale-progess .sale-desc {
  padding: 24px;
  border-radius: 24px;
  background: #f5b54410;
  font-weight: 700;
  font-size: 14px;
  color: #091b3d;
}
.new-agent-stripcard img {
  max-width: 48px;
  max-height: 48px;
  border-radius: 16px;
  margin-right: 1rem;
}
.new-agent-stripcard {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
  margin-bottom: 24px;
}
.new-agent-info p {
  font-size: 14px;
  color: #091b3d;
}
.new-agent-stripcard .col-2 {
  color: #2772f0;
}
.history-heading {
  background: #de350b10;
  padding: 24px;
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #091b3d;
  border-radius: 16px;
}
.transaction-strip {
  font-family: "Cera Pro", sans-serif;
  font-weight: 700;
  padding: 12px 24px;
}
.transaction-strip .payment-number {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.transaction-strip .payment-number {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.transaction-strip .transaction-icon {
  width: 48px;
  height: 48px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
}
.transaction-strip .transaction-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.transaction-strip .transaction-info p {
  font-size: 14px;
  color: #091b3d;
}
.transaction-strip .transaction-info p span {
  font-size: 14px;
  color: #2772f0;
}
.transaction-strip .transaction-info span {
  font-size: 14px;
  color: #091b3d50;
}
.transaction-strip .payment-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #091b3d;
}
.transaction-strip .payment-status {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.transaction-strip .payment-status span {
  font-size: 12px;
  padding: 5px 8px;
}
.history-payment-scuccess .transaction-icon {
  color: #4b9f47;
  background: #4b9f4710;
}
.history-refund-scuccess .transaction-icon {
  color: #f5b544;
  background: #f5b54410;
}
.history-payment-fail .transaction-icon {
  color: #e6521f;
  background: #e6521f10;
}

.viewall-transaction-btn button {
  font-family: "Cera Pro", sans-serif;
  padding: 16px 0;
  text-align: center;
  border: 1px solid rgba(9, 27, 61, 0.1);
  border-radius: 16px;
  background: #fff;
  font-weight: 700;
  font-size: 14px;
  color: #2772f0;

  &:hover {
    background: #fbfbfb;
  }
}
// admin graph section end

@keyframes blowOut {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.1, 1.2);
  }
}

.term-plans {
  padding-top: 40px;
  background: linear-gradient(180deg, #ee4854 0%, #f16e77 100%);
}
.page-title {
  font-size: 24px;
  font-family: "DM Sans";
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  line-height: 32px;
}
.term-plans-form {
  position: relative;
  z-index: 3;
  background: #ffffff;
  border-radius: 15px;
  padding: 48px;
  background: #ffffff;
  border-radius: 15px;
  max-width: 50%;
  margin: 0 auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.term-plans-form h6 {
  font-size: 14px;
  color: #676767;
  margin-bottom: 24px;
}

.term-plans-form .form-check .form-check-label {
  font-size: 9px;
  font-weight: normal;
}
.radio-content {
  margin-bottom: 15px;
}
.radio-content label {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 14px;
  padding: 0 8px;
  margin-bottom: 5px;
}
.radio-group-wrapper {
  display: flex;
  border: 1px solid var(--primary);
  height: 54px;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  gap: 5px;
}
.radio-group-wrapper label {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #f8f8f8;
  margin: 0;
}
.radio-group-wrapper label.active {
  background-color: var(--primary);
  color: #fff;
}

.radio-group-wrapper label input {
  display: none;
}
.term-plans-form .btn-primary {
  width: 100%;
  text-align: center;
}

.pagination-btns button {
  border-radius: 18px;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #4e4e4e;
  background: none;
  border: none;
  padding: 6px 10px;
  max-width: 48px;
  &:hover {
    color: #ee4854;
  }
}
.pagination-btns button:first-child,
.pagination-btns button:last-child {
  border: 2px solid #4e4e4e25;
  color: #ee4854;
  &:hover {
    border: 2px solid #ee485440;
  }
}
.pagination-btns button.active {
  color: #ee4854;
}

.add-question-card.question-card * {
  font-family: "DM Sans";
  font-weight: 600;
}
.add-question-card.question-card span {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #eee;
  border-radius: 50%;
}
.add-question-card.question-card h5 {
  width: 100%;
}
.add-question-card.question-card h5 input {
  font-size: 16px;
}

.training-module .accordion-button:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}
.training-module .accordion-button:not(.collapsed) {
  background-color: transparent !important;
  color: #ee4854;
}
.training-module .accordion-button::after {
  filter: sepia(100%) hue-rotate(-30deg) saturate(960%);
}

@media screen and (max-width: 767px) {
  .container-fluid {
    padding-left: 16px;
    padding-right: 16px;
  }
  .hide-in-mobile {
    display: none !important;
  }
  .show-in-mobile {
    display: block;
  }

  .term-plans {
    padding-bottom: 40px;
  }

  .term-plans-form {
    margin: 0;
    max-width: 100%;
    padding: 24px 18px;
  }
}

// @media screen and (max-width: 414px) {
//   .insurance-plan-right-side {
//     display: none;
//   }
// }

.nav-tabs {
  border: none !important;
}

.training-posid {
  background: #eed9db;
  border-radius: 0px 0px 20px 20px;
  // position: fixed;
  // z-index: 999;
}
.training-posid h6 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  margin: 1rem 0;
  color: #000000;
}

.download-btn {
    align-items: center;
    background-color: rgba(240, 240, 240, 0.26);
    border: 1px solid red;
    border-radius: 16px;
    box-sizing: border-box;
    color: red;
    cursor: pointer;
    display: flex;
    font-family: Inter, sans-serif;
    font-size: 12px;
    justify-content: center;
    line-height: 28px;
    max-width: 100%;
    padding: 1px 4px;
    text-decoration: none;
    transition: all .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
  }
  
  .download-btn:active,
  .download-btn:hover {
    outline: 0;
  }
  
  .download-btn:hover {
    background-color: #FFFFFF;
    border-color: rgba(22, 7, 7, 0.50);
  }
  
  @media (min-width: 768px) {
    .download-btn {
      font-size: 20px;
      min-width: 100px;
      padding: 4px 8px;
    }
  }
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    display: flex;
    background-color: #eee;
    font-family: "Gotham", sans-serif;
  }
  .training-certificate {
    background: #ffffff;
    width: 100%;  
  }
  .main {
    max-width: 794px;
    width: 100%;
    padding: 1.5rem;
    margin: 0 auto;
    background: #ffffff;
    position: relative;
    box-shadow: 0px 0px 10px #ddd;
  }
  .main::after {
      content: '';
      position: absolute;
      background-image: url(/public/assets/images/vector-top.png);
      top: 0;
      right: -200px;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
  }
  .main::before {
      content: '';
      position: absolute;
      background-image: url(/public/assets/images/Vector-bottom.png);
      background-repeat: no-repeat;
      overflow: hidden;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 73px;
  }
  
  .content {
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 15px;
    padding: 20px 34px;
  }
  .broker-name * {
    font-weight: 600;
    font-family: "LitSans", sans-serif;
  }
  .broker-name span {
    font-size: 100px;
    line-height: 90px;
  }
  .broker-name p {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    margin-left: 5px;
  }
  p.certificate-heading {
    font-family: "LitSans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    margin: 3rem 0;
  }
  p.certificate-para {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.6px;
    word-spacing: 2px;
  }
  p.doc {
    font-weight: 600;
    font-size: 13px;
    line-height: 28px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }
  p.result-desc {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: 0.7px;
    word-spacing: 3px;
  }
  p.letter-desc {
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.4px;
    line-height: 21px;
    word-spacing: 2px;
    color: #6c6c6c;
  }
  p.for-broker {
    font-weight: 600;
    font-size: 17px;
    line-height: 28px;
  }
  .widthSign img{
    width: 40%;
  }

  span.sign {
      font-weight: 600;
      font-size: 15px;
      line-height: 28px;
  }
  p.broker-address {
      font-size: 13px;
      line-height: 18px;
  }
  
  @media print {
      body {
          background-color: #fff;
        }
      @page {
          size: A4;
          margin: 0;
        }
        .training-certificate {
            transform: inherit !important;
          }
        .main {
          margin-top: 9.5rem;
          transform: scale(1.35);
        }
   }